import { toast, cssTransition } from 'react-toastify';

const Fade = cssTransition({
  enter: 'fadeIn',
  exit: 'fadeOut',
  duration: 750,
});

toast.configure({
  // autoClose: false,
  autoClose: 3000,
  draggable: false,
  position: 'bottom-center',
  pauseOnFocusLoss: true,
  hideProgressBar: true,
  closeButton: false,
  transition: Fade,
});

// toastMessage('test', ToastType.ERROR);
