export const NOT_FOUND = "/404";
export const HOME_URL = "/";
export const TEAM_URL = "/team";
export const ABOUT_URL = "/about-us";
export const CONTACT_URL = "/contact-us";
export const SERVICES_URL = "/services";
export const DATABASE_MICRO_SERVICE_URL = "/database-micro-service-solutions";
export const STRATERGY_CONSULTING_SERVICE_URL =
  "/stratergy-and-consulting-service";
export const OFFSHORE_OUTSOURCING_SERVICE_URL = "/offshore-outsourcing-service";
export const DATABASE_MANAGEMENT_AND_OPTIMIZATION_SERVICE_URL =
  "/database-management-and-optimization";
export const DATABSE_MIGRATIONS_SERVICE_URL = "/database-migration-service";
export const CLOUD_MIGRATION_SERVICE_URL = "/cloud-migration-service";
export const DEVOPS_CONSULTING_SERVICE_URL = "/devops-consulting-service";
export const CI_CD_INTEGRATION_SERVICE_URL = "/ci-cd-integration-service";
export const DATA_ENGINEERING_SERVICE_URL = "/data-engineering-and-analytics";
export const CUSTOM_SOFTWARE_DEVELOPMENT_SERVICE_URL =
  "/custom-software-development-service";
export const WEB_DESIGN_AND_DEVELOPMENT_SERVICE_URL =
  "/web-design-and-development";
export const MOBILE_APP_DEVELOPMENT_SERVICE_URL = "/mobile-app-development";
