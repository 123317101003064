/* eslint-disable no-param-reassign */
import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import {
  ABOUT_URL,
  CI_CD_INTEGRATION_SERVICE_URL,
  CLOUD_MIGRATION_SERVICE_URL,
  CONTACT_URL,
  CUSTOM_SOFTWARE_DEVELOPMENT_SERVICE_URL,
  DATABASE_MANAGEMENT_AND_OPTIMIZATION_SERVICE_URL,
  DATABASE_MICRO_SERVICE_URL,
  DATABSE_MIGRATIONS_SERVICE_URL,
  DATA_ENGINEERING_SERVICE_URL,
  DEVOPS_CONSULTING_SERVICE_URL,
  HOME_URL,
  MOBILE_APP_DEVELOPMENT_SERVICE_URL,
  OFFSHORE_OUTSOURCING_SERVICE_URL,
  STRATERGY_CONSULTING_SERVICE_URL,
  WEB_DESIGN_AND_DEVELOPMENT_SERVICE_URL,
  NOT_FOUND,
} from "./constants/route";

import "./assets/css/style.css";
import "./assets/css/full-width-header.css";
import "./utils/configure-toast";
import "react-toastify/dist/ReactToastify.css";

const Home = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Contact = lazy(() => import("./pages/Contact"));
const CICDService = lazy(() => import("./pages/CICDService"));
const CloudMigrationService = lazy(() =>
  import("./pages/CloudMigrationService")
);
const ConsultingService = lazy(() => import("./pages/ConsultingService"));
const CustomDevelopmentService = lazy(() =>
  import("./pages/CustomDevelopmentService")
);
const DatabaseManagementService = lazy(() =>
  import("./pages/DatabaseManagementService")
);
const DatabaseMigrationSerivice = lazy(() =>
  import("./pages/DatabaseMigrationSerivice")
);
const DataEngineeringSerivice = lazy(() =>
  import("./pages/DataEngineeringSerivice")
);
const DevOpsService = lazy(() => import("./pages/DevOpsService"));
const MicroService = lazy(() => import("./pages/MicroService"));
const MobileAppService = lazy(() => import("./pages/MobileAppService"));
const OffshoreService = lazy(() => import("./pages/OffshoreService"));
const WebDesignService = lazy(() => import("./pages/WebDesignService"));

// All lazy loaded components need to be wrapped in <Suspense />
const withSuspense = (Component) => (props) => (
  <Suspense fallback={<div></div>}>
    <Component {...props} />
  </Suspense>
);

const App = () => {
  const disableRightClcik = () => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  };

  useEffect(() => {
    disableRightClcik();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path={HOME_URL} component={withSuspense(Home)} />
        <Route exact path={ABOUT_URL} component={withSuspense(AboutUs)} />
        <Route exact path={CONTACT_URL} component={withSuspense(Contact)} />
        <Route
          exact
          path={DATABASE_MICRO_SERVICE_URL}
          component={withSuspense(MicroService)}
        />
        <Route
          exact
          path={STRATERGY_CONSULTING_SERVICE_URL}
          component={withSuspense(ConsultingService)}
        />
        <Route
          exact
          path={OFFSHORE_OUTSOURCING_SERVICE_URL}
          component={withSuspense(OffshoreService)}
        />
        <Route
          exact
          path={DATABASE_MANAGEMENT_AND_OPTIMIZATION_SERVICE_URL}
          component={withSuspense(DatabaseManagementService)}
        />
        <Route
          exact
          path={DATABSE_MIGRATIONS_SERVICE_URL}
          component={withSuspense(DatabaseMigrationSerivice)}
        />
        <Route
          exact
          path={CLOUD_MIGRATION_SERVICE_URL}
          component={withSuspense(CloudMigrationService)}
        />
        <Route
          exact
          path={DEVOPS_CONSULTING_SERVICE_URL}
          component={withSuspense(DevOpsService)}
        />
        <Route
          exact
          path={CI_CD_INTEGRATION_SERVICE_URL}
          component={withSuspense(CICDService)}
        />
        <Route
          exact
          path={DATA_ENGINEERING_SERVICE_URL}
          component={withSuspense(DataEngineeringSerivice)}
        />
        <Route
          exact
          path={CUSTOM_SOFTWARE_DEVELOPMENT_SERVICE_URL}
          component={withSuspense(CustomDevelopmentService)}
        />
        <Route
          exact
          path={WEB_DESIGN_AND_DEVELOPMENT_SERVICE_URL}
          component={withSuspense(WebDesignService)}
        />
        <Route
          exact
          path={MOBILE_APP_DEVELOPMENT_SERVICE_URL}
          component={withSuspense(MobileAppService)}
        />
        <Route path={"*"}>
          <Redirect to={NOT_FOUND} />
        </Route>
      </Switch>
    </Router>
  );
};
export default App;
